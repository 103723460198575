import DeleteReportService from "@/service/DeleteReportService";

import { showToast, showErrorToast } from "@/helpers/actions/helpers";

class DeleteReportActions {
    items = [];
    menuItems= [];

    beforeReset = null;
    afterReset = null;

    beforeResend = null;
    afterResend = null;

    constructor(data) {
        if (data.status !== 'new' && data.fileId !== null) {
            this.items.push({
                label: 'Скачать файл',
                icon: 'pi pi-fw pi-download',
                command: () => this.download(data.id)
            });
        }

        if (data.status === 'new' || data.status === 'error') {
            this.items.push({
                label: 'Сгенерировать повторно',
                icon: 'pi pi-fw pi-replay',
                command: () => this.reset(data.id),
                viewOnly: true
            });
        }

        if (data.status === 'completed') {
            this.items.push({
                label: 'Переотправить отчёт',
                icon: 'pi pi-fw pi-replay',
                command: () => this.resend(data.id),
                viewOnly: true
            });
        }

        this.menuItems = this.items.filter((item) => item.viewOnly !== true);
    }

    reset(id) {
        if (this.beforeReset != null)
            this.beforeReset();

        DeleteReportService.reset(id).then((res) => {
            showToast(res.feedback, res.error);
        })
        .catch((err) => {
            showErrorToast(err);
        })
        .finally(() => {
            if (this.afterReset != null)
                this.afterReset();
        })
    }

    resend(id) {
        if (this.beforeResend != null)
            this.beforeResend();

        DeleteReportService.resend(id).then((res) => {
            showToast(res.feedback, res.error);
        })
        .catch((err) => {
            showErrorToast(err);
        })
        .finally(() => {
            if (this.afterResend != null)
                this.afterResend();
        })
    }

    download(id) {
        window.open(`/download/delete_report/${id}`, '_blank');
    }

    getItems() {
        return this.items;
    }

    getMenuItems() {
        return this.menuItems;
    }
}

export default DeleteReportActions;