<template>
  <div>
    <Card>
      <template #title>{{ title ? title : 'Отчёты' }}</template>
      <template #content>
        <AbstractTable :code="loanId !== undefined ? 'reportsByLoan' : 'reports'" ref="dataTable" :default-data="{eventId: eventId, loanId: loanId}" display-filter="menu">
          <template #additionalButtons>
            <Button
                icon="pi pi-refresh"
                class="p-button-rounded p-button-outlined"
                @click="$refs.dataTable.getData()"
            ></Button>
          </template>
          <template #id="row">
            <IdChip
              :id="row.data.id"
              view="report_view"
              :menu-items="generateItems(row.data)"
              toast-template='Идентификатор отчёта "{v}" скопирован'
            />
          </template>
          <template #bureau="row">
            {{ $filters.translate($store, 'reportBureaus', row.data.bureau) }}
          </template>
          <template #status="row">
            <StatusChip
              :value="row.data.status"
              compendium="processStatuses"
            />
          </template>
          <template #filename="row">
            <ValueChip
              :value="row.data.filename"
              toast-template='Название файла отчёта "{v}" скопировано'
            />
          </template>
        </AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
import StatusChip from "@/components/Common/StatusChip";
import IdChip from "@/components/Common/IdChip.vue";
import ValueChip from "@/components/Common/ValueChip.vue";
export default {
  name: "ReportsTable",
  components: {ValueChip, IdChip, StatusChip, AbstractTable},
  props: {
    title: {
      type: String
    },
    loanId: {
      type: Number
    },
    eventId: {
      type: Number
    }
  },
  methods: {
    generateItems(data) {
      let items = [];

      if (data.fileId !== null) {
        items.push(
          {
            label: 'Скачать файл',
            icon: 'pi pi-fw pi-download',
            command: () => this.downloadReport(data.id),
          }
        )
      }
      return items
    },
    downloadReport(id) {
      window.open('/download/report/' + id, '_blank')
    }
  }
}
</script>

<style scoped>

</style>