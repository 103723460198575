import rpc from "@/rpc";

class DeleteReportService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    reset(id) {
        return this.rpc.execute('resetDeleteReport', {
            id
        })
    }
    
    resend(id) {
        return this.rpc.execute('resendDeleteReport', {
            id
        })
    }
}

export default new DeleteReportService(rpc)