<template>
  <div>
    <Card>
      <template #title>Отчёты на удаление</template>
      <template #content>
        <AbstractTable code="deleteReports" ref="dataTable" display-filter="menu">
          <template #additionalButtons>
            <Button
                icon="pi pi-refresh"
                class="p-button-rounded p-button-outlined"
                @click="$refs.dataTable.getData()"
            ></Button>
          </template>
          <template #id="row">
            <IdChip
              :id="row.data.id"
              view="delete_report_view"
              :menu-items="generateItems(row.data)"
              toast-template='Идентификатор отчёта на удаление "{v}" скопирован'
            />
          </template>
          <template #bureau="row">
            {{ $filters.translate($store, 'deleteReportBureaus', row.data.bureau) }}
          </template>
          <template #type="row">
            {{ $filters.translate($store, 'deleteReportTypes', row.data.type) }}
          </template>
          <template #status="row">
            <StatusChip
              :value="row.data.status"
              compendium="processStatuses"
            />
          </template>
          <template #filename="row">
            <ValueChip
              :value="row.data.filename"
              toast-template='Название файла отчёта на удаление "{v}" скопировано'
            />
          </template>
        </AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
import StatusChip from "@/components/Common/StatusChip";
import IdChip from "@/components/Common/IdChip.vue";
import ValueChip from "@/components/Common/ValueChip.vue";
import DeleteReportActions from "@/helpers/actions/deleteReportActions";

export default {
  name: "DeleteReportsTable",
  components: {ValueChip, IdChip, StatusChip, AbstractTable},
  methods: {
    generateItems(data) {
      const actions = new DeleteReportActions(data);
      return actions.getMenuItems();
    }
  }
}
</script>

<style scoped>

</style>