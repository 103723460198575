<template>
  <div>
    <Card>
      <template #title>{{ title ? title : 'События' }}</template>
      <template #content>
        <AbstractTable code="events" ref="dataTable" display-filter="menu" :default-data="defaultData" :ignored-fields="ignoredFields">
          <template #additionalButtons>
            <Button
                icon="pi pi-refresh"
                class="p-button-rounded p-button-outlined"
                @click="$refs.dataTable.getData()"
            ></Button>
          </template>
          <template #id="row">
            <div>
              <IdChip
                :id="row.data.id"
                view="event_view"
                :view-params="null"
                :view-query="{ eventId: row.data.id, eventDate: row.data.eventDate, locale: row.data.locale }"
                :menu-items="generateItems(row.data)"
                toast-template='Идентификатор события "{v}" скопирован'
              />
            </div>
          </template>
          <template #applicationUuid="row">
            <ValueChip
              :value="row.data.applicationUuid"
              toast-template='Идентификатор заявки "{v}" скопирован'
            />
          </template>
          <template #cbUuid="row">
            <ValueChip
              :value="row.data.cbUuid"
              toast-template='ЦБ УИД "{v}" скопирован'
            />
          </template>
          <template #fio="row">
            {{ row.data.borrower.surname }} {{ row.data.borrower.name }} {{ row.data.borrower.patronymic }}
          </template>
          <template #type="row">
            {{ $filters.translate($store, 'eventType', row.data.type) }}
          </template>
        </AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
import IdChip from "@/components/Common/IdChip.vue";
import ValueChip from "@/components/Common/ValueChip.vue";
import EventActions from "@/helpers/actions/eventActions";
export default {
  name: "EventsTable",
  components: {ValueChip, IdChip, AbstractTable},
  props: {
    title: {
      type: String
    },
    defaultData: {
      type: Object,
    },
    ignoredFields: {
      type: Array
    }
  },
  methods: {
    generateItems(data) {
      const actions = new EventActions(data);
      actions.afterDelete = this.refresh;
      actions.afterDeleteFromBureaus = this.refresh;
      return actions.getMenuItems();
    },
    refresh() {
      this.$refs.dataTable.setLoading(true);
      this.$refs.dataTable.getData();
    }
  }
}
</script>