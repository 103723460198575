<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Основная информация</template>
        <template #content>
          <TabView>
            <TabPanel header="События">
              <EventsTable></EventsTable>
            </TabPanel>
            <TabPanel header="Отчёты">
              <ReportsTable></ReportsTable>
            </TabPanel>
            <TabPanel header="Отчёты на удаление">
              <DeleteReportsTable></DeleteReportsTable>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>

import EventsTable from "@/components/tables/EventsTable";
import ReportsTable from "@/components/tables/ReportsTable";
import DeleteReportsTable from "@/components/tables/DeleteReportsTable";
export default {
  name: "Main",
  components: {DeleteReportsTable, ReportsTable, EventsTable},
}
</script>

<style scoped>

</style>