<template>
  <Chip
    :label="$filters.translate($store, compendium, value)"
    class="mr-2 mb-2"
    :class="getColorClass()"
  />
</template>

<script>
export default {
  name: 'StatusChip',
  props: {
    value: {
      type: String,
      required: true
    },
    compendium: {
      type: String,
      required: true
    }
  },
  methods: {
    getColorClass() {
      switch (this.value) {
        case 'completed':
          return 'success-chip'
        case 'error':
          return 'danger-chip'
        default:
          return 'default-chip'
      }
    }
  }
}
</script>

<style scoped>
.p-chip.default-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

.p-chip.success-chip {
  background: #22C55E;
  color: var(--primary-color-text);
}

.p-chip.danger-chip {
  background: #EF4444;
  color: var(--primary-color-text);
}
</style>